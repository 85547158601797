import CertGenFooter from '@components/CertGen/CertGenFooter/index';
import CertGenHero from '@components/CertGen/CertGenHero/index';
import CertGenNavigation from '@components/CertGen/CertGenNavigation/index';
import SEO from '@components/Common/SEO/index';
import React from 'react';

export default function Certificate(props) {
  const { slug } = props.pageContext?.pageData;

  return (
    <>
      <SEO title="Certificate Share" />
      <CertGenNavigation />
      <CertGenHero program={slug} />
      <CertGenFooter />
    </>
  );
}
