import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';

export default function FormIcon({
  icon,
  error,
  validated = false,
  className,
}) {
  return (
    <div
      className={`absolute top-4 right-4 flex h-[30px] w-[30px] items-center justify-center p-1 text-white ${
        validated
          ? 'bg-v5-neutral-300'
          : error
          ? 'bg-v5-red-200'
          : 'bg-v5-neutral-300'
      } rounded-2xl ${className ? className : ''}`}
    >
      {validated ? (
        <FontAwesomeIcon icon="fa-solid fa-check" />
      ) : error ? (
        <FontAwesomeIcon icon={faExclamation} style={{ color: '#FFFFFF' }} />
      ) : (
        icon
      )}
    </div>
  );
}
