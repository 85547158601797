import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext, useState } from 'react';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { FormProvider, useForm } from 'react-hook-form';
import FormInput from '@components/CertGen/CertGenInputFields/FormInput';
import FormPhoneInput from '@components/CertGen/CertGenInputFields/FormPhoneInput';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import FormDropDown from '@components/CertGen/CertGenInputFields/FormDropDown';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import {
  employmentStatus,
  ERROR_LEAD_SOURCE,
  generateDropDownValues,
  getGraduationYearList,
  LEAD_GEN_DATA_SOURCE,
  whitelistedLeadSources,
} from '@src/constants/LeadGenerationConstants/index';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { CertGenFormValidationSchema } from '@src/constants/cert-gen/index';
import { navigate } from 'gatsby';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import { toPng } from 'html-to-image';
import ReactDOMServer from 'react-dom/server';
import BaseTemplate from '@src/templates/CertGen/BaseTemplate';
import strapiDb from '@src/data/db.json';
import { omit } from 'lodash';
import LeadGenerationService from '../../../../../utils/lead-generation';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import { toast } from 'react-hot-toast';

export default function CertGenHeroRightSection({ program }) {
  //Global State:
  const globalState = useContext(GlobalStateContext);
  const storedParams = globalState.utm;
  const searchParam = new URLSearchParams(storedParams);
  const utmSource = searchParam.get('utm_source');
  const utmMedium = searchParam.get('utm_medium');
  const utmCampaign = searchParam.get('utm_campaign');

  const [isLoading, setIsLoading] = useState(false);

  const hookForm = useForm({
    mode: 'onBlur',
    resolver: yupResolver(CertGenFormValidationSchema),
  });

  const getCertificateDetails = () => {
    const certificateDetails =
      strapiDb.strapi_metadata?.externalCertificates?.find(
        (certificate) => certificate.slug === program,
      );

    return {
      name: '',
      programName: certificateDetails?.programName,
      skills: certificateDetails?.skills.map((skill) => skill.skillName),
    };
  };

  const certificateDetails = getCertificateDetails();

  function dataURLToBlob(dataURL) {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  const generateCertificateUrl = async (imgDataUrl, leadGenformData) => {
    const url =
      'https://crio-s3-link-gen-ckcgbchpd0fbetd2.southindia-01.azurewebsites.net/v1/getS3Url';
    const image = dataURLToBlob(imgDataUrl);
    const formData = new FormData();
    formData.append('image', image, 'image.jpg');
    const fileName = `${leadGenformData.phone}_${Date.now()}.jpg`;
    formData.append('fileName', fileName);

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      if (response.ok) {
        return Promise.resolve(data.s3Url);
      } else {
        return Promise.reject();
      }
    } catch (err) {
      console.log('error', err);
      return Promise.reject();
    }
  };

  const generateCertImage = useCallback(
    async (data) => {
      let tempDiv;
      try {
        certificateDetails.name = data.name;

        const htmlString = ReactDOMServer.renderToString(
          <BaseTemplate certificateDetails={certificateDetails} />,
        );

        tempDiv = document.createElement('div');
        tempDiv.style.height = '1080px';
        tempDiv.style.width = '1920px';
        tempDiv.innerHTML = htmlString;
        document.body.appendChild(tempDiv);

        const dataUrl = await toPng(tempDiv);
        console.log(dataUrl);

        const certUrlPromise = generateCertificateUrl(dataUrl, data);

        toast.promise(certUrlPromise, {
          loading: 'Generating Certificate...',
          success: 'Certificate Generated!',
          error: 'Failed to generate certificate',
        });

        const certUrl = await certUrlPromise;

        sessionStorage.setItem('certificateUrl', certUrl);
        sessionStorage.setItem('certificateImage', dataUrl);
        sessionStorage.setItem('currentProgram', program);
        sessionStorage.setItem('email', data.email);

        navigate('/certificate/share/');
      } catch (err) {
        console.error('Error generating image:', err);
      } finally {
        if (tempDiv) {
          document.body.removeChild(tempDiv);
        }
      }
    },
    [certificateDetails, program],
  );

  const handleButtonClick = async (data) => {
    if (data) {
      try {
        setIsLoading(true);
        data.phone = '+'.concat(data.phone);
        data.email = data.email.toLowerCase();
        // add data source key
        data.dataSource = LEAD_GEN_DATA_SOURCE;
        data.graduationYear = parseInt(data.graduationYear);
        data.currentlyWorking = 'Working Professional' ? 'Yes' : 'No';
        // add UTM data
        if (utmSource) {
          if (whitelistedLeadSources.includes(utmSource))
            data.utmSource = utmSource;
          else data.utmSource = ERROR_LEAD_SOURCE;
        }
        if (utmMedium) data.utmMedium = utmMedium;
        if (utmCampaign) data.utmCampaign = utmCampaign;

        data = omit(data, ['employmentStatus']);

        await LeadGenerationService.generateLead(data);

        const leadAndActivityPayload = {
          LeadDetails: [
            {
              Attribute: 'EmailAddress',
              Value: data.email.toLowerCase(),
            },
          ],
          Activity: {
            ActivityEvent: ELeadSquaredActivityCode.CERTIFICATE_GENERATION,
            Fields: [
              { SchemaName: 'mx_Custom_1', Value: '' },
              { SchemaName: 'mx_Custom_2', Value: 'No' },
              { SchemaName: 'mx_Custom_3', Value: utmSource || '' },
              { SchemaName: 'mx_Custom_4', Value: utmMedium || '' },
              { SchemaName: 'mx_Custom_5', Value: utmCampaign || '' },
              { SchemaName: 'mx_Custom_6', Value: program || '' },
            ],
          },
        };

        await LeadGenerationService.queueLeadAndActivity(
          leadAndActivityPayload,
        );

        await generateCertImage(data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="flex w-full max-w-[500px] flex-col gap-8 justify-self-center rounded-md bg-v5-neutral-200 pt-9 font-manrope">
      <div className="text-center text-[20px] font-bold leading-[23px]">
        Please Fill in the Form
      </div>
      <form
        className="flex w-full flex-col gap-3 px-4 md:px-8"
        onSubmit={hookForm.handleSubmit(handleButtonClick)}
      >
        <FormProvider {...hookForm}>
          <FormInput
            icon={<FontAwesomeIcon icon={faAddressCard} />}
            name="name"
            label="Full name"
            isLightMode={true}
          />
          <FormPhoneInput
            error={hookForm.formState.errors?.phone?.message}
            validation={
              hookForm.formState.dirtyFields?.phone &&
              !hookForm.formState.errors?.phone
            }
          />
          <FormInput
            icon={<FontAwesomeIcon icon={faEnvelope} />}
            label="Email"
            name="email"
            error={hookForm.formState.errors?.email?.message}
          />
          <FormDropDown
            icon={<FontAwesomeIcon icon={faBriefcase} />}
            name="employmentStatus"
            label="Employment Status"
            menu_items={generateDropDownValues(employmentStatus)}
          />
          <FormDropDown
            icon={<FontAwesomeIcon icon="fa-solid fa-graduation-cap" />}
            name="graduationYear"
            label="Graduation Year"
            menu_items={getGraduationYearList()}
          />
          <LeadGenerationButton
            text={isLoading ? 'Loading...' : 'Get Your Certificate'}
            wrapperClassName="w-[70%] mx-auto mt-3 mb-14"
            onClick={handleButtonClick}
          />
        </FormProvider>
      </form>
    </div>
  );
}
