import React from 'react';
import CertGenHeroLeftSection from './CertGenHeroLeftSection/index';
import CertGenHeroRightSection from './CertGenHeroRightSection/index';

export default function CertGenHero({ program }) {
  return (
    <div className="grid grid-cols-[1fr] justify-center gap-10 py-3 px-9 font-manrope md:grid-cols-[1fr_1fr] md:gap-24 md:py-28 md:px-28">
      <CertGenHeroLeftSection />
      <CertGenHeroRightSection program={program} />
    </div>
  );
}
