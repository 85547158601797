import * as yup from 'yup';

// Regex for Indian phone numbers; TODO: create a seperate regex array for all-country validation
const indianRegex = new RegExp('^[6-9][0-9]{9}$');

export const CertGenFormValidationSchema = yup.object({
  name: yup
    .string()
    .required('Name is a required field')
    .test(
      'noSpaceChars',
      'Name cannot be empty or contain only spaces',
      (value) => {
        return value && value.trim().length > 0;
      },
    ),
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Email is a required field'),
  phone: yup
    .string()
    .required('Phone is a required field')
    .test('Check Indian Number', function () {
      let contact = this.parent['phone'];

      if (contact && contact.startsWith('91')) {
        let contactWithoutCountryCode = contact.substring(2, contact.length);
        return indianRegex.test(contactWithoutCountryCode);
      }

      return contact?.length >= 8; // temporary logic to validate at-least 8 digit phone number (8 digits include country code)
    }),
  graduationYear: yup
    .number()
    .typeError('Must be a number')
    .positive('Must be a positive number')
    .integer('Must be an integer'),
  employmentStatus: yup.string(),
});
