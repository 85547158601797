import React from 'react';
import Light from './Light.svg';
import CrioCertified from './CrioCertified.svg';
import Signature from './Signature.svg';

const BaseTemplate = ({ certificateDetails }) => {
  const { name, programName, skills } = certificateDetails;
  return (
    <>
      <div className={`h-[1080px] w-[1920px] bg-v5-green-200 p-[14px]`}>
        <div className="relative h-[504px] bg-white pt-8 pl-6">
          <div className="absolute">
            <Light />
          </div>

          <div className="absolute right-0 top-0 h-[207px] w-[207px] object-cover">
            <CrioCertified />
          </div>
          <div className="mt-10 flex flex-col items-center justify-center font-manrope">
            <div className="mb-6 text-[43px] font-semibold leading-[58px]">
              Kudos
            </div>
            <div className="mx-auto mb-[24px] w-full text-center text-[64px] font-extrabold leading-[77px]">
              {name}
            </div>
            <div className="mb-[57px] text-[34px] font-semibold leading-[58px]">
              You have successfully completed the learning session on
            </div>
            <div className="w-full text-center font-rubik text-[86px] font-bold italic leading-[45px]">
              {programName}
            </div>
          </div>
        </div>
        <div className="mt-[94px] mb-[33px] flex w-full flex-col items-center justify-center font-manrope">
          <div className="mb-[75px] text-[47px] font-semibold leading-[37px]">
            Skills Acquired
          </div>
          <div className="flex gap-[70px]">
            {skills.map((skill) => (
              <div className="flex items-center justify-center rounded-md bg-white px-[40px] py-2 font-rubik text-[39px] font-medium leading-normal">
                {skill}
              </div>
            ))}
          </div>
          <div className="h-[179px] w-[695px]">
            <Signature />
          </div>
        </div>
      </div>
    </>
  );
};

export default BaseTemplate;
