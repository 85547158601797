import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { Controller } from 'react-hook-form';
import FormIcon from './FormIcon';
import 'react-phone-input-2/lib/material.css';
import './phone-input-light.css';

export default function FormPhoneInput({ error, validation, handleFocus }) {
  return (
    <div
      className={`light-phone-input-container relative inline-block ${
        error ? 'pb-4' : ''
      }`}
    >
      <Controller
        name="phone"
        render={({ field }) => (
          <PhoneInput
            country="in"
            placeholder="Phone"
            className="light-phone-input"
            name="phone"
            countryCodeEditable={false}
            onFocus={handleFocus}
            defaultErrorMessage={error}
            isValid={() => {
              if (typeof window !== 'undefined' && document) {
                error
                  ? document.documentElement.style.setProperty(
                      '--phone-input-color',
                      '#F3736A',
                    )
                  : document.documentElement.style.setProperty(
                      '--phone-input-color',
                      '#7A8B87',
                    );
              }
              return error ? false : true;
            }}
            inputProps={{
              required: true,
            }}
            inputStyle={{
              width: '100%',
              color: '#525252',
              border: error ? `none` : '1px solid #7A8B87',
              ...(error
                ? { background: '#fff6f6' }
                : { background: '#FFFFFF' }),

              outline: 'none',
            }}
            {...field}
          />
        )}
      ></Controller>
      <FormIcon
        icon={<FontAwesomeIcon icon={faPhone} />}
        error={error}
        validated={validation}
      />
    </div>
  );
}
