import React from 'react';
import { TextField } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import FormIcon from './FormIcon';

export default function FormInput({ name, label, icon, handleFocus }) {
  const formContext = useFormContext();
  const error = formContext.formState.errors[name]?.message;

  return (
    <div className="relative inline-block">
      <Controller
        name={name}
        control={formContext.control}
        render={({ field }) => (
          <TextField
            variant="filled"
            label={label}
            {...field}
            helperText={error}
            fullWidth
            FormHelperTextProps={{
              style: {
                color: '#F3736A',
              },
            }}
            onFocus={handleFocus}
            InputProps={{
              disableUnderline: true,
              style: error
                ? {
                    color: '#ff0000',
                    backgroundColor: '#fff6f6',
                  }
                : {
                    color: '#525252',
                    padding: 3,
                    borderRadius: '16px',
                    fontFamily: 'Rubik',
                    border: '1px solid #7A8B87',
                    backgroundColor: '#FFFFFF',
                  },
            }}
            InputLabelProps={{
              style: {
                ...(error
                  ? {
                      color: '#F3736A',
                      fontWeight: '500',
                      fontSize: '12px',
                      fontFamily: 'Rubik',
                      paddingTop: '5px',
                    }
                  : {
                      color: '#7A8B87',
                      fontWeight: '500',
                      fontSize: '12px',
                      fontFamily: 'Rubik',
                      paddingTop: '5px',
                    }),
              },
            }}
            className={`${
              error ? ' bg-[#ffeded]' : 'bg-white'
            } rounded-2xl text-black placeholder:text-v5-green-300`}
            inputProps={{ 'aria-label': `Enter ${label}` }}
          ></TextField>
        )}
      ></Controller>
      <FormIcon
        icon={icon}
        error={error}
        validated={
          formContext.formState.dirtyFields[name] &&
          !formContext.formState.errors[name]
        }
      />
    </div>
  );
}
